import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "../utils/array-util"

const LinkMap = {
  "Design Patterns": "3b0f67ae6488",
  "Erich Gamma": "3b0f67ae6488",
  "Richard Helm": "3b0f67ae6488",
  "Ralph Johnson": "3b0f67ae6488",
  "John Vlissides": "3b0f67ae6488",
  "Grady Booch": "3b0f67ae6488",
  "Problem-Solution": "3b0f67ae6488",
  "Creational Patterns": "3b0f67ae6488",
  "Behavioral Patterns": "3b0f67ae6488",
  "Structural Patterns": "3b0f67ae6488",

  "Abstact Factory": "74f611282bca",
  "Builder Pattern": "b610ed47e986",
  "Factory Method": "57c2f3bb89d6",
  Prototype: "8d8c0acae8e",
  Singleton: "a499afaad377",

  Adapter: "b9a9245d5a12",
  Bridge: "7c5c74801c70",
  Composite: "bf5ad9786f66",
  Decorator: "f72002c4493f",
  Facade: "4887dc68bd0e",
  Flyweight: "b2a4acb7b0ca",
  Proxy: "2ea3382af36c",

  "Chain Of Responsibility": "4c2661a4d235",
  Command: "7918fed781ed",
  Interpreter: "37844b4420bf",
  Mediator: "670fffbae76c",
  Memento: "9bf30a763683",
  Observer: "aa2958dfa3a6",
  State: "8567ec008fe",
  Strategy: "7ceafcd38a7",
  "Template Method": "d9e0d0d1f474",
  Visitor: "e64f7741e2a",
}

const storiesGroup = [
  {
    title: "Tasarım Örüntüleri",
    storyPart: [
      {
        title: "Tasarım Örüntüleri",
        postId: "3b0f67ae6488",
      },
    ],
  },
  {
    title: "Creational Patterns",
    storyPart: [
      {
        title: "Abstact Factory",
        postId: "74f611282bca",
      },
      {
        title: "Builder Pattern",
        postId: "b610ed47e986",
      },
      {
        title: "Factory Method",
        postId: "57c2f3bb89d6",
      },
      {
        title: "Prototype",
        postId: "8d8c0acae8e",
      },
      {
        title: "Singleton",
        postId: "a499afaad377",
      },
    ],
  },
  {
    title: "Structural Patterns",
    storyPart: [
      {
        title: "Adapter",
        postId: "b9a9245d5a12",
      },
      {
        title: "Bridge",
        postId: "7c5c74801c70",
      },
      {
        title: "Composite",
        postId: "bf5ad9786f66",
      },
      {
        title: "Decorator",
        postId: "f72002c4493f",
      },
      {
        title: "Facade",
        postId: "4887dc68bd0e",
      },
      {
        title: "Flyweight",
        postId: "b2a4acb7b0ca",
      },
      {
        title: "Proxy",
        postId: "2ea3382af36c",
      },
    ],
  },
  {
    title: "Behavioral Patterns",
    storyPart: [
      {
        title: "Chain Of Responsibility",
        postId: "4c2661a4d235",
      },
      {
        title: "Command",
        postId: "7918fed781ed",
      },
      {
        title: "Interpreter",
        postId: "37844b4420bf",
      },
      {
        title: "Mediator",
        postId: "670fffbae76c",
      },
      {
        title: "Memento",
        postId: "9bf30a763683",
      },
      {
        title: "Observer",
        postId: "aa2958dfa3a6",
      },
      {
        title: "State",
        postId: "8567ec008fe",
      },
      {
        title: "Strategy",
        postId: "7ceafcd38a7",
      },
      {
        title: "Template Method",
        postId: "d9e0d0d1f474",
      },
      {
        title: "Visitor",
        postId: "e64f7741e2a",
      },
    ],
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "Mimari Tasarım Örüntüler",
  path: "oo-design-pattern",
}

const OODesignPatternsPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default OODesignPatternsPage
